import { SanityPageLayout } from "../components/Layouts/SanityPageLayout";
import { enrichSections, pathFromParams } from "../lib/PageData";
import { getSanityStaticPaths, getSanityStaticProps } from "../lib/sanity/sanity.query";
import type { SanityPage } from "../lib/sanity/sanity.types";
interface Props {
  page: SanityPage;
  preview: boolean;
}
export default function SanityPage({
  page,
  preview
}: Props) {
  return <SanityPageLayout page={page} preview={preview} data-sentry-element="SanityPageLayout" data-sentry-component="SanityPage" data-sentry-source-file="[[...path]].tsx" />;
}
export const getStaticProps = async req => {
  const {
    params,
    preview = false
  } = req;
  const path = pathFromParams(params);
  const page: SanityPage = await getSanityStaticProps(path, preview).catch(() => null);
  if (!page) {
    return {
      notFound: true
    };
  }
  await enrichSections(page, preview);
  return {
    props: {
      page,
      preview
    }
  };
};

// These are paths that shouldn't be caught by the catch all route
const EXCLUDE_PATHS = ["/careers"];
export const getStaticPaths = async ({
  preview
}) => {
  const pagePaths = await getSanityStaticPaths(preview);
  const paths = pagePaths.filter(path => !EXCLUDE_PATHS.includes(path));
  return {
    paths,
    fallback: false
  };
};